import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from 'react';
import Backdrop from './BackdropSection';
import HeaderButton from "./HeaderButton";

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  let navClasses = 'nav-links';
  let navItemClasses = 'nav-link-item';
  let burgerClass = "burger";
  let backdropClass = "backdrop";
  if (isOpen) {
    navClasses = 'nav-links nav-active';
    navItemClasses = 'nav-link-item-active';
    burgerClass = "burger toggle";
    backdropClass = "backdrop backdrop-active";
  }
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  console.log("url : " + url)

  return (
    <header>
      <Backdrop activeClass={backdropClass} click={toggle} />
      <nav className="navbar">
        <div className="nav-belt">
          <div>
            <h4>
              <Link className="logo" to="/" >
                <div className="logo-image"></div>     
                {siteTitle}
              </Link>
            </h4>
          </div>
          <ul className={navClasses}>
            <li className={navItemClasses}><Link to="/" className={url == "/" ? "is-current-url" : ""}>Accueil</Link></li>
            <li className={navItemClasses}><Link to="/a-propos" className={url == "/a-propos" ? "is-current-url" : ""}>A propos</Link></li>
            <li className={navItemClasses}><Link to="/portfolio" className={url == "/portfolio" ? "is-current-url" : ""}>Portfolio</Link></li>
            <li className={navItemClasses}><Link to="/blog" className={url == "/blog" ? "is-current-url" : ""}>Blog</Link></li>
            <li><Link to="/#CTA"><HeaderButton label="Contactez-nous" /></Link></li>
            
          </ul>
          <div className={burgerClass} onClick={toggle}>
            <div className="burger-line-1"></div>
            <div className="burger-line-2"></div>
            <div className="burger-line-3"></div>
          </div>
        </div>
      </nav>
    </header>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
