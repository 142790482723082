import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Img from "gatsby-image";
import { Link } from 'gatsby';

export default function ArticleDetails  ({data}) {
  const { html } = data.markdownRemark
  const { title, tags, featuredImg, date } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <div className="article-detail">
        <Img className="article-detail-IMG" fluid={featuredImg.childImageSharp.fluid}/>
        <div className="article-detail-content">
          <p className="article-detail-link"><Link to="/">Accueil</Link> &#62; Blog</p>
          <h3>{tags}</h3>
          <h1>{title}</h1>
          <h5>{new Date(date).toLocaleDateString()}</h5>
          <h5>Auteur : Adrian Guery | Catégorie : {tags}</h5>
          <div className="article-detail-message-content" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ProjectDetails($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
      tags
      date
      featuredImg {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`